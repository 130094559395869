import React, { useState } from "react";
import "./InfluencerOverview.scss";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Hand from "../assets/Hand.svg";
import Mediainfo from "../assets/Mediainfo.svg";
import Audience from "../assets/Audience.svg";
import BackArrow from "../assets/BackArrow.svg";
import { Link } from "react-router-dom";
import Line from "../assets/Line.svg";
import CollabIcon from "../assets/collab icon.svg";
import Popup from "../CampaignPopup/popup";
import Requested from "../assets/Requested.svg";
import Accepted from "../assets/Accepted.svg";

function InfluencerOverview() {
  const location = useLocation();
  const { campaign } = location.state || {};

  const { user } = useSelector((state) => state?.user || {});
  const [popupVisible, setPopupVisible] = useState(false);

  // Function to handle click on Collaborate button
  const handleCollaborateClick = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };
  const calculateCounts = (timeline) => {
    const counts = {
      Instagram: {},
      YouTube: {},
    };

    timeline.forEach((week) => {
      Object.keys(week).forEach((platform) => {
        if (typeof week[platform] === "object") {
          Object.keys(week[platform]).forEach((item) => {
            counts[platform][item] =
              (counts[platform][item] || 0) + week[platform][item];
          });
        }
      });
    });

    return counts;
  };

  let formattedInstagramCounts = "";
  let formattedYouTubeCounts = "";

  if (campaign?.timeline) {
    const platformCounts = calculateCounts(campaign?.timeline);
    const formatPlatformCounts = (platform) => {
      const items = Object.keys(platformCounts[platform]);
      const nonZeroItems = items.filter(
        (item) => platformCounts[platform][item] !== 0
      );
      return nonZeroItems
        .map((item) => `${platformCounts[platform][item]} ${item}`)
        .join(", ");
    };

    formattedInstagramCounts = formatPlatformCounts("Instagram");
    formattedYouTubeCounts = formatPlatformCounts("YouTube");
  }

  function formatDateRange(startDate, endDate) {
    const startDay = new Date(startDate).getDate();
    const startMonth = new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
    });

    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const endYear = new Date(endDate).getFullYear();

    return `From ${startDay}th ${startMonth} to ${endDay}th ${endMonth} ${endYear}`;
  }

  const formatGenders = (genderJson) => {
    try {
      const genderArray = JSON.parse(genderJson);

      if (Array.isArray(genderArray)) {
        return genderArray.join(", ");
      } else if (typeof genderArray === "string") {
        return genderArray;
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error parsing gender JSON:", error);
      return "";
    }
  };

  const formatLanguages = (languagesJson) => {
    try {
      const languagesArray = JSON.parse(languagesJson);

      return languagesArray.map((language, index) => (
        <span
          key={index}
          style={{
            width: "40px",
            height: "23px",
            borderRadius: "5px",
            border: "0.5px solid rgba(0, 0, 0, 0.5)",
            background: "#fff",
            color: "rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            padding: "5px",
            lineHeight: "normal",
            marginRight: "5px",
          }}
        >
          {language.slice(0, 3)}
        </span>
      ));
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  const ageGroups = campaign?.age_group || [];

  let minAge = Infinity;
  let maxAge = -Infinity;

  ageGroups.forEach((ageRange) => {
    const [start, end] = ageRange.split("-");
    const startAge = parseInt(start, 10);
    const endAge = parseInt(end, 10);

    minAge = Math.min(minAge, startAge);
    maxAge = Math.max(maxAge, endAge);
  });

  const consolidatedAgeRange = `${minAge}-${maxAge}`;

  return (
    <div className="campaign-overview-details">
      <Link to="/campaign" className="back-to-campaign-home-button">
        <img src={BackArrow} alt="back" />
        Back
      </Link>
      <div className="campaign-overview-header">
        <span className="campaign-details-header">Campaign Details</span>
      </div>
      <div className="campiagn-content">
        <span className="campaign-title">{campaign?.campaign_title}</span>
        <span className="campaign-obj">Objective : {campaign?.campaign_obj}</span>
        <span className="desc">Description : {campaign?.description}</span>
        <span className="compensation">Compensation</span>
        <div className="compensation-info">
          <img src={Hand} alt="Hand" />
          <span>{campaign?.compensation_type}</span>
          <span>.</span>

          <span>{campaign?.budget}</span>
        </div>
        <span className="deliverables">Expected Deliverables</span>
        <div className="deliverables-info">
          <img src={Mediainfo} alt="Mediainfo" />
          <div>Weekly</div>
          <div>timeline</div>

          <div>
            {formattedYouTubeCounts && `${formattedYouTubeCounts} &`}
            {formattedInstagramCounts}
          </div>
          {formattedYouTubeCounts || formattedInstagramCounts ? (
            <span>•</span>
          ) : null}
          <span>
            {campaign
              ? formatDateRange(campaign?.start_date, campaign?.end_date)
              : ""}
          </span>
        </div>
        <span className="target-audience">Target Audience</span>
        <div className="target-audience-info">
          <img src={Audience} alt="Audience" />
          <span>
            Age :-{" "}
            {campaign?.age_group &&
              campaign.age_group.length > 0 &&
              consolidatedAgeRange}
          </span>
          <span>
            {campaign?.age_group && campaign.age_group.length > 0 && (
              <span>•</span>
            )}
          </span>
          <span>
            Gender :- {campaign ? formatGenders(campaign.gender) : ""}
          </span>
          <span>
            {campaign?.gender && campaign.gender.length > 0 && <span>•</span>}
          </span>
          <span>{user?.area}</span>
          <span>
            {campaign?.language && campaign.language.length > 0 && ""}
          </span>
          <span>
            Languages :- {campaign ? formatLanguages(campaign.language) : ""}
          </span>
        </div>
        <div className="influencer-buttons">
          <button className="view-details-button">
            Save Campaign
            <img src={Line} alt="Edit Icon" />
          </button>
          <button
            className="collaborate-button"
            onClick={() => {
              if (campaign?.request_status === null) {
                handleCollaborateClick(
                  campaign?.campaign_id,
                  campaign?.user_id
                );
              }
            }}
          >
            {campaign?.request_status === null && (
              <>
                <span>Collaborate</span>
                <img src={CollabIcon} alt="link" />
              </>
            )}
            {campaign?.request_status === "Pending" && (
              <>
                <span>Request Sent</span>
                <img src={Requested} alt="link" />
              </>
            )}
            {campaign?.request_status === "Accepted" && (
              <>
                <span>Req. Accepted</span>
                <img src={Accepted} alt="link" />
              </>
            )}
          </button>
        </div>
      </div>

      <Popup open={popupVisible} onClose={handleClosePopup} />
    </div>
  );
}

export default InfluencerOverview;
