import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import ConfirmationPopup from "./ConfirmationPopup";
import { useDispatch } from "react-redux";
import { makeCampaignRequestByInfluencer } from "../../actions/campaignAction";
import CloseIcon from "@mui/icons-material/Close"; // Import the Close icon

function CollaboratePopup({ open, onClose, onSubmit, campaignId, brand_id }) {
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleCollabSubmit = () => {
    if (message.trim() !== "") {
      setConfirmationOpen(true);
      dispatch(
        makeCampaignRequestByInfluencer({
          campaign_id: campaignId,
          brand_id: brand_id,
          message: message,
        })
      );
    }
  };

  const handleRequestWithoutNote = () => {
    dispatch(
      makeCampaignRequestByInfluencer({
        campaign_id: campaignId,
        brand_id: brand_id,
      })
    );
    setConfirmationOpen(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleConfirmationClose = () => {
    setConfirmationOpen(false);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "10px",
            fontFamily: "Poppins, sans-serif",
            padding: "20px",
            overflow: "visible",
          },
        }}
      >
        {/* Title */}
        <DialogTitle
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          You Can Customize this Collaboration Request{" "}
          <Typography
            component="span"
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              color: "gray",
              marginLeft: "5px",
            }}
          >
            (Optional)
          </Typography>
          
          {/* Close Button */}
          <IconButton
            onClick={handleClose}
            sx={{
              position: "absolute",
              top: "8px",
              left :"0px",
              right: "20px",
              color: "gray",
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* Content */}
        <DialogContent sx={{ marginTop: "-10px" }}>
          <DialogContentText
            sx={{
              fontSize: "16px",
              color: "gray",
              marginBottom: "10px",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "500",
              lineHeight: "24px",
            }}
          >
            Spice up your pitch! Drop a quick line to show why you're the
            perfect match.
          </DialogContentText>
          <TextField
            autoFocus
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            placeholder="Increase your chances! A personal touch can go a long way in securing collaborations."
            InputProps={{
              style: {
                fontFamily: "Poppins, sans-serif",
                borderRadius: "8px",
                color: "#333",
              },
            }}
            onChange={handleInputChange}
          />
          <Typography
            align="right"
            sx={{
              fontSize: "12px",
              color: "gray",
              marginTop: "3px",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            0/100
          </Typography>
        </DialogContent>

        {/* Actions */}
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            borderTop: "1px solid #f0f0f0",
            marginTop: "12px",
            padding: "15px 24px",
          }}
        >
          {/* Request Without Note */}
          <Typography
            onClick={handleRequestWithoutNote}
            sx={{
              cursor: "pointer",
              color: "#e43a67",
              fontFamily: "Poppins, sans-serif",
              textDecoration: "underline",
              fontSize: "14px",
              height: "41px",
            }}
          >
            Send Request without note
          </Typography>

          {/* Submit Button */}
          <Button
            onClick={handleCollabSubmit}
            sx={{
              backgroundColor: "#e43a67",
              color: "white",
              fontFamily: "Poppins, sans-serif",
              padding: "10px 24px",
              fontSize: "14px",
              textTransform: "none",
              borderRadius: "8px",
              width: "230px",
              "&:hover": {
                backgroundColor: "#d42a5c",
              },
            }}
          >
            Send Collab Request
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmationPopup
        open={confirmationOpen}
        onClose={() => handleConfirmationClose()}
        message={message}
        withNote={message.trim() !== ""}
      />
    </>
  );
}

export default CollaboratePopup;
