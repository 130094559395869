import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Youtube from "../assets/ytSVG.svg";
import "./YoutubeAccordion.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DownArrow from "../assets/iconamoon.svg";

import PieChart from "./PieChart";
import ProgressBar from "@ramonak/react-progress-bar";
import ActivityAreaChart from "./ActivityAreaCharts";
import EvolutionAreaChart from "./EvolutionAreaChart";
import { Box, Grid } from "@mui/material";
export default function AccordionExpandIcon() {
  const reachData = [
    { name: "Male", value: 40 },
    { name: "Female", value: 30 },
    { name: "Others", value: 20 },
  ];
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon style={{ visibility: "hidden" }} />}
      >
        <Box className="stat-item" style={{ width: "100%", marginRight: "0" }}>
          {/* <div className="wrapper-div"> */}
          <Grid
            container
            spacing={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            {/* Contact Section */}
            <Grid item xs={12} sm={12} md={1}>
              <div className="youtube-logo">
                <div className="youtube-image">
                  <img src={Youtube} alt="youtube" />
                </div>

                <p>Youtube</p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              {" "}
              <div className="post-interaction">
                <h3>Post Interaction</h3>
                <div className="interaction-stats">
                  <div className="interaction-stat-field">
                    <p>6K</p>
                    <span>Avg.Likes</span>
                  </div>
                  <div className="interaction-stat-field">
                    <p>4K</p>
                    <span>Avg.Comments</span>
                  </div>
                  <div className="interaction-stat-field">
                    <p>2K</p>
                    <span>Avg.Shares</span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <div className="post-interaction">
                <h3>Social Presence</h3>
                <div className="interaction-stats">
                  <div className="interaction-stat-field">
                    <p>120k</p>
                    <span>Subscribers</span>
                  </div>
                  <div className="interaction-stat-field">
                    <p>44K</p>
                    <span>Reach</span>
                  </div>
                  <div className="interaction-stat-field">
                    <p>10%</p>
                    <span>Engagement</span>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={1} textAlign={"center"}>
              <div className="arrow-div">
                <img src={DownArrow} alt="open" />
              </div>
            </Grid>
          </Grid>
          {/* </div> */}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>
          <div className="parent-box">
            <div className="insta-stats">
              <h3>Audience</h3>
              <div className="group-stats">
                <div className="city-stats">
                  <p>
                    Cities <span>(Top 5)</span>{" "}
                  </p>
                  <div className="progress-bars">
                    <div className="single-stats-unit">
                      <p> Mumbai</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>
                    <div className="single-stats-unit">
                      <p> Delhi</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p> Bengaluru</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p> Pune</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p> Goa</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>
                  </div>
                </div>
                <div className="followers-stats">
                  <PieChart reachData={reachData} />
                </div>

                <div className="city-stats">
                  <p>Age</p>
                  <div className="progress-bars">
                    <div className="single-stats-unit">
                      <p>13-17</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p> 18-24</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p> 25-34</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p>35-44</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        fontSize="10px" // Light pink color for uncovered part
                        baseBgColor="#F7DBE0"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                        height="5px"
                        width="10rem"
                      />
                    </div>

                    <div className="single-stats-unit">
                      <p> 45-60</p>{" "}
                      <ProgressBar
                        completed={60}
                        bgColor="#F45C7B"
                        labelAlignment="outside"
                        labelColor="#6B4750"
                        isLabelVisible="false"
                        fontSize="5px"
                        baseBgColor="#F7DBE0"
                        height="5px"
                        width="10rem"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Gender PieChart Section */}
              <figure className="charts">
                <figcaption className="legends">
                  <span>Other</span>
                  <span>Female</span>
                  <span>Male</span>
                </figcaption>
              </figure>
            </div>
            <div className="account-activity">
              <h3>
                Account Activity <span>(No. Of Posts)</span>
              </h3>

              <ActivityAreaChart />
            </div>
            <div className="subscribers-chart">
              <h3>Subscribers Evolution Chart</h3>

              <EvolutionAreaChart />
            </div>
          </div>
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
}
