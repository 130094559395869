import React, { useEffect, useState } from "react";
import CollabIcon from "../assets/collab icon.svg";
import Ongoing from "../assets/Ongoing.svg";
import Calender from "../assets/Calender.svg";
import Location from "../assets/Location.svg";
import Scheduled from "../assets/Scheduled.svg";
import Line from "../assets/Line.svg";
import { useNavigate } from "react-router-dom";
import SearchIcon from "../assets/Search.svg";
import Vector from "../assets/Vector.svg";
import Poster from "../assets/Poster.png";
import Save from "../assets/Save.svg";
import SavedIcon from "../assets/Saved.svg";
import "./InfluencerCampaign.scss";
import Popup from "../CampaignPopup/popup";
import Requested from "../assets/Requested.svg";
import SavedCampaign from "../assets/SavedCampaign.svg";
import Accepted from "../assets/Accepted.svg";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import { Stepper, Step, StepLabel } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllCampaignsForInfluencer,
  saveCampaign,
} from "../../actions/campaignAction";
import {
  MAKE_CAMPAIGN_RESET_BY_INFLUENCER,
  SAVE_CAMPAIGN_RESET,
} from "../../constants/campaignConstants";
import MuiLoader from "../layout/Loader/MuiLoader";
import { X } from "@mui/icons-material";

function CampaignCard({ campaign }) {
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedCampaignId, setSelectedCampaignId] = useState(null);
  const [brandIdOfCampaign, setBrandIdOfCampaign] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.user || {});
  const { isCampaignSaved } = useSelector((state) => state?.save || false);
  const { requestMade } = useSelector(
    (state) => state?.influencerCampaignRequest || false
  );

  const handleCollaborateClick = (campaign_id, brand_id) => {
    setSelectedCampaignId(campaign_id);
    setBrandIdOfCampaign(brand_id);
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (requestMade) {
      // toast.success("Request sent successfully", {
      //   position: "top-right",
      //   autoClose: 3000,
      //   bodyClassName: "custom-toast-body",
      // });
      dispatch({ type: MAKE_CAMPAIGN_RESET_BY_INFLUENCER });
      dispatch(getAllCampaignsForInfluencer());
    }
  }, [requestMade]);

  useEffect(() => {
    if (isCampaignSaved) {
      dispatch({ type: SAVE_CAMPAIGN_RESET });
      dispatch(getAllCampaignsForInfluencer());
    }
  }, [isCampaignSaved, dispatch]);

  const handleDetailClick = (campaign) => {
    navigate("/influenceroverview", { state: { campaign } });
  };

  const truncateTitle = (title, maxLength) => {
    if (title.length > maxLength) {
      return `${title.slice(0, maxLength)}...`;
    }
    return title;
  };

  // stepperrr

  const formatLanguages = (languagesJson) => {
    try {
      const languagesArray = JSON.parse(languagesJson);
      return languagesArray.map((language, index) => (
        <span
          key={index}
          style={{
            width: "40px",
            height: "23px",
            borderRadius: "5px",
            border: "0.5px solid rgba(0, 0, 0, 0.5)",
            background: "#fff",
            color: "rgba(0, 0, 0, 0.5)",
            textAlign: "center",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "normal",
            marginRight: "5px",
          }}
        >
          {language.slice(0, 3)}
        </span>
      ));
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return "";
    }
  };

  const getCurrentDate = () => new Date();

  const isOngoing = (campaign) => {
    if (!campaign) {
      return false;
    }

    const currentDate = getCurrentDate();
    let isOngoing =
      campaign.issavedasdraft === 0 &&
      campaign.isautopublished === 0 &&
      currentDate >= new Date(campaign.start_date) &&
      currentDate <= new Date(campaign.end_date);

    return isOngoing;
  };

  const isScheduledForLater = (campaign) => campaign.isautopublished === 1;

  function formatDateRange(startDate, endDate) {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const start = new Date(startDate).toLocaleDateString("en-US", options);
    const end = new Date(endDate).toLocaleDateString("en-US", options);

    const startDay = new Date(startDate).getDate();
    const startMonth = new Date(startDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const startYear = new Date(startDate).getFullYear();

    const endDay = new Date(endDate).getDate();
    const endMonth = new Date(endDate).toLocaleDateString("en-US", {
      month: "short",
    });
    const endYear = new Date(endDate).getFullYear();

    return `${startDay}th ${startMonth} to ${endDay}th ${endMonth} ${endYear}`;
  }

  const countInstagramTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.Instagram?.reels || 0) +
        (weekData.Instagram?.posts || 0) +
        (weekData.Instagram?.story || 0);
      return acc;
    }, 0);
  };

  const countYouTubeTotal = (timelineData) => {
    return timelineData?.reduce((acc, weekData) => {
      acc +=
        (weekData.YouTube?.videos || 0) + (weekData.YouTube?.live_stream || 0);
      return acc;
    }, 0);
  };

  const handleOnSave = (campaign_id, action) => {
    dispatch(saveCampaign({ campaignId: campaign_id, action: action }));
  };

  // cards
  return (
    <div className="campaign-card">
      <div className="campaign-image">
        <img src={campaign?.campaign_poster || Poster} alt="Campaign" />
        {campaign.issaved === false && (
          <div
            className="save-campaign"
            onClick={() => handleOnSave(campaign?.campaign_id, "save")}
          >
            <img src={SavedIcon} alt="Save Icon" />
          </div>
        )}
        {campaign.issaved === true && (
          <div
            className="save-campaign"
            onClick={() => handleOnSave(campaign?.campaign_id, "unsave")}
          >
            <img src={SavedCampaign} alt="Saved Icon" />
          </div>
        )}
      </div>
      <div className="campaign-content">
        <div className="brand-info">
          <img
            src={campaign?.creator_user_photo || Poster}
            alt="Brand Logo"
            className="brand-logo"
          />
          <span className="brand-name">{campaign.creator_first_name}</span>
        </div>
        <div className="campaign-status">
          <span>{truncateTitle(campaign?.campaign_title, 16)}</span>
          {isOngoing(campaign) && (
            <div>
              <img src={Ongoing} alt="Ongoing Icon" />
              <span className="ongoing">Ongoing</span>
            </div>
          )}
          {isScheduledForLater(campaign) && (
            <div>
              <img src={Scheduled} alt="Draft Icon" />
              <span className="scheduled">Upcoming</span>
            </div>
          )}
        </div>
        <div className="aim">{truncateTitle(campaign?.campaign_obj, 30)}</div>
        <div className="campaign-dates">
          {campaign?.start_date && campaign?.end_date && (
            <div className="date-range">
              <img src={Calender} alt="Calender Icon" />
              <span>
                {(campaign?.start_date && campaign?.end_date && (
                  <span>
                    {formatDateRange(campaign.start_date, campaign.end_date)}
                  </span>
                )) || <span className="missing-info">Date missing</span>}
              </span>
            </div>
          )}
        </div>
        <div className="location-calendar-info">
          <img src={Location} alt="Calender Icon" />
          <span>
            {(user?.area && <span>{user?.area}</span>) || (
              <span className="missing-info">Location missing</span>
            )}
          </span>
        </div>
        <div className="line"></div>
        <div className="timeline">
          <span className="deliverablesTitle">Deliverables</span>
          <span>
            {(campaign && campaign.timeline && (
              <div className="required-content">
                {campaign.timeline && (
                  <div className="instagram-total">
                    <span>{`${countInstagramTotal(
                      campaign.timeline
                    )} from Insta `}</span>
                  </div>
                )}
                {/* <span>•</span> */}
                {campaign.timeline && (
                  <div className="youtube-total">
                    <span>{`${countYouTubeTotal(
                      campaign.timeline
                    )} from YT`}</span>
                  </div>
                )}
                {/* {campaign.language && <span>•</span>} */}
                {campaign.language && (
                  <div className="languages">
                    {formatLanguages(campaign.language)}
                  </div>
                )}
              </div>
            )) || (
              <div className="required-content">
                <span>Deliverables missing</span>
              </div>
            )}
          </span>
        </div>
        <div className="buttons">
          <div
            className="view-details-button"
            onClick={() => handleDetailClick(campaign)}
          >
            <span>View Details</span>
            <img src={Line} alt="Edit Icon" />
          </div>
          <div
            className="collaborate-button"
            onClick={() => {
              if (campaign?.request_status === null) {
                handleCollaborateClick(
                  campaign?.campaign_id,
                  campaign?.user_id
                );
              }
            }}
          >
            {campaign?.request_status === null && (
              <>
                <span>Collaborate</span>
                <img src={CollabIcon} alt="link" />
              </>
            )}
            {campaign?.request_status === "Pending" && (
              <>
                <span>Request Sent</span>
                <img src={Requested} alt="link" />
              </>
            )}
            {campaign?.request_status === "Accepted" && (
              <>
                <span>Req. Accepted</span>
                <img src={Accepted} alt="link" />
              </>
            )}
          </div>
        </div>
        <Popup
          open={popupVisible}
          onClose={handleClosePopup}
          campaignId={selectedCampaignId}
          brand_id={brandIdOfCampaign}
        />
      </div>
    </div>
  );
}

function InfluencerCampaign({ route }) {
  const { user } = useSelector((state) => state.user);

  const [savedCampaign, setSavedCampaign] = useState(false);
  const [currentStatus, setcurrentStatus] = useState(
    user.role === "brand" ? "All" : "Pending"
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const dispatch = useDispatch();
  const { campaigns, loading } = useSelector((state) => state?.campaigns || {});

  useEffect(() => {
    dispatch(getAllCampaignsForInfluencer());
  }, [dispatch]);

  const goToSavedCampaign = () => {
    setSavedCampaign((prevSavedCampaign) => !prevSavedCampaign);
  };

  const filteredCampaigns = savedCampaign
    ? (campaigns && campaigns.filter((campaign) => campaign.issaved)) || []
    : (campaigns &&
        campaigns.filter((campaign) => {
          const currentDate = new Date().setHours(0, 0, 0, 0);
          const campaignStartDate = new Date(campaign.start_date).setHours(
            0,
            0,
            0,
            0
          );

          // Show all campaigns for "brand" users; otherwise, exclude future campaigns
          if (user.role === "brand") {
            return true;
          }

          return campaignStartDate <= currentDate; // Exclude future campaigns for non-"brand" users
        })) ||
      [];

  const handleStepClick = (value) => {
    setcurrentStatus(value);
  };

  return (
    <div className="influencers-campaign-dashboard">
      <div className="container">
        <div className="influencer-campaign-search">
          <Grid
            container
            alignItems="end"
            justifyContent={"flex-end"}
            spacing={0}
          >
            {/* Search Section */}
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <div className="search">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className="search-icon"
                />
                <input
                  className="search-bar"
                  type="text"
                  placeholder="Search for Campaigns"
                />
              </div>
            </Grid>

            {/* Saved Section */}
            <Grid item xs={route !== "home" ? 12 : 6} sm={6} md={6} lg={2}>
              <div
                className="saved"
                style={{
                  marginTop: "2%",
                }} /* Specify the desired width here */
                onClick={goToSavedCampaign}
              >
                <span>Saved</span>
                <img
                  src={savedCampaign ? SavedCampaign : SavedIcon}
                  alt="Dashboard Icon"
                />
              </div>
            </Grid>

            {/* Filters Section (Visible only in "home" route) */}
            {route === "home" && (
              <Grid item xs={6} sm={6} md={6} lg={2}>
                {/* <Grid item xs={6} sm={6} md={2} lg={2}> */}
                <div
                  className="dashboard"
                  style={{
                    marginTop: "2%",
                    marginRight: "3%",
                  }} /* Specify the desired width here */
                >
                  <span>Filters</span>
                  <img src={Vector} alt="Vector Icon" />
                  {/* </Grid> */}
                </div>
              </Grid>
            )}
          </Grid>
        </div>

        {/* Stepper Section */}
        {route === "campaign" && (
          <div className="campaign-stepper">
            <Stepper activeStep={0} alternativeLabel>
              {[
                { lable: "Request Sent", value: "Pending" },
                { lable: "Request Accepted", value: "Accepted" },
                { lable: "Request Rejected", value: "Rejected" },
              ].map((ele) => (
                <Step
                  key={ele.lable}
                  onClick={() => handleStepClick(ele.value)} // Handle click event
                >
                  <StepLabel>{ele.lable}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
        )}

        <div className="influencer-campaigns">
          {loading ? (
            <MuiLoader />
          ) : (
            <>
              {/* Display campaigns based on the current step */}

              <Grid
                container
                spacing={3}
                justifyContent="flex-start" // Centers items horizontally
                alignItems="center"
                sx={{ overflowX: "hidden" }} // Adds overflow-x: hidden
              >
                {[
                  ...(route === "campaign"
                    ? filteredCampaigns.filter(
                        (ele) => ele?.request_status === currentStatus
                      )
                    : filteredCampaigns),
                ].map((campaign, index) => (
                  <Grid item xs={12} sm={6} md={6} lg={4} xl={3} key={index}>
                    <CampaignCard campaign={campaign} />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default InfluencerCampaign;

// [
//   ...(route === "campaign"
//     ? filteredCampaigns.filter((ele) => ele?.request_status === "Pending")
//     : filteredCampaigns),
// ];
