import React, { useEffect, useState } from "react";
import "./InfluencerDashboard.scss";
import SearchIcon from "../assets/Search.svg";
import Heart from "../assets/Heart.svg";
import Liked from "../assets/Liked.svg";
import Unliked from "../assets/Like.svg";
import People from "../assets/People.svg";
import Sort from "../assets/Sort.svg";
import Grid from "../assets/Grid.svg";
import ListIcon from "../assets/List.svg";
import Instagram from "../assets/Instagram.svg";
import Facebook from "../assets/Facebook.svg";
import YouTube from "../assets/YouTube.svg";
import User from "../assets/User.png";
import Vector from "../assets/Vector.svg";
import Profile_Photo_Rec from "../assets/Profile_Photo_Rec.png";
import Verify from "../assets/Verify.svg";
import Important from "../assets/Important.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getAllInfluencers,
  getFavoriteInfluencers,
  getSearchedInfluencers,
  updateInfluencerLikes,
} from "../../actions/influencersAction";
import { Popover } from "@mui/material";
import MuiLoader from "../layout/Loader/MuiLoader";

function InfluencerDashboard() {
  const [view, setView] = useState(true);
  const [filterPopUp, setFilterPopUp] = useState(null);
  const [followersPopUp, setFollowersPopUp] = useState(null);
  const dispatch = useDispatch();
  const [currentFilter, setCurrentFilter] = useState("Engagement");
  const [selectedOptions, setSelectedOptions] = useState({});
  const [filterOptionsData, setFilterOptionsData] = useState({});
  const [isFetchingFavorites, setIsFetchingFavorites] = useState(false);
  const [sortParams, setSortParams] = useState({
    isfollowerssortedasc: false,
    isfollowerssorteddesc: false,
    isengagementsortedasc: false,
    isengagementsorteddesc: false,
  });
  const [activeTab, setActiveTab] = useState("Brands");

  const { user } = useSelector((state) => state.user);

  const columnWidths = [20, 10, 15, 20, 15, 10, 25, 10];

  const filteroptions = {
    Engagement: [
      { label: "2% - 4%", value: "2-4" },
      { label: "5% - 7%", value: "5-7" },
      { label: "8% - 10%", value: "8-10" },
      { label: "11% - 13%", value: "11-13" },
      { label: "14% - 16%", value: "14-16" },
      { label: "16% +", value: "16+" },
    ],
    Reach: [
      { label: "O - 100K", value: "0-100000" },
      { label: "100K - 200K", value: "100000-200000" },
      { label: "200K - 300K", value: "200000-300000" },
      { label: "300K - 400K", value: "300000-400000" },
      { label: "400K - 500K", value: "400000-500000" },
      { label: "500K - 700K", value: "500000-700000" },
      { label: "700K - 900K", value: "700000-900000" },
      { label: "900K +", value: "900000 +" },
    ],
    Followers: [
      { label: "10K - 100K", value: "10000-100000" },
      { label: "100K - 300K", value: "100000-300000" },
      { label: "300K - 500K", value: "300000-500000" },
      { label: "500K - 700K", value: "500000-700000" },
      { label: "700K - 900K", value: "700000-900000" },
      { label: "900K - 1M", value: "900000-1000000" },
      { label: "1M - 3M", value: "1000000-3000000" },
      { label: "3M +", value: "3000000 +" },
    ],
    Genre: [
      { label: "Art & Photography", value: ["Art", "Photography"] },
      {
        label: "Business & Entrepreneurship",
        value: ["Business", "Entrepreneurship"],
      },
      { label: "Entertainment", value: "Entertainment" },
      {
        label: "Fitness, Fashion & Beauty",
        value: ["Fitness", "Fashion", "Beauty"],
      },
      { label: "Lifestyle & Travel", value: ["Lifestyle", "Travel"] },
      { label: "Tech & Gaming", value: ["Tech", "Gaming"] },
      { label: "Others", value: "Others" },
    ],
    Age: [
      { label: "13 - 17", value: "13-17" },
      { label: "18 - 24", value: "18-24" },
      { label: "25 - 34", value: "25-34" },
      { label: "35 - 44", value: "35-44" },
      { label: "45 - 54", value: "45-54" },
      { label: "55 - 64", value: "55-64" },
      { label: "65 +", value: "65+" },
    ],
    City: [
      { label: "Mumbai", value: "Mumbai" },
      { label: "Delhi", value: "Delhi" },
      { label: "Bangalore", value: "Bangalore" },
      { label: "Chennai", value: "Chennai" },
      { label: "Kolkata", value: "Kolkata" },
      { label: "Jaipur", value: "Jaipur" },
      { label: "Hyderabad", value: "Hyderabad" },
      { label: "Other Cities", value: "Other Cities" },
    ],
  };
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigate = useNavigate();
  useEffect(() => {
    setInterval(() => {
      if (!isAuthenticated) {
        navigate("/login");
      }
    }, 3000);
  }, []);
  const formatNumber = (number) => {
    if (number < 1000) {
      return number.toString();
    } else if (number < 1000000) {
      const formatted = (number / 1000).toFixed(1);
      return formatted.endsWith(".0")
        ? `${Math.floor(number / 1000)}k`
        : `${formatted}k`;
    } else if (number < 1000000000) {
      const formatted = (number / 1000000).toFixed(1);
      return formatted.endsWith(".0")
        ? `${Math.floor(number / 1000000)}M`
        : `${formatted}M`;
    } else {
      const formatted = (number / 1000000000).toFixed(1);
      return formatted.endsWith(".0")
        ? `${Math.floor(number / 1000000000)}B`
        : `${formatted}B`;
    }
  };

  function handleClearAll() {
    setSelectedOptions({});
    setFilterOptionsData({});
  }

  function handleButtonClick(event) {
    const button = event.currentTarget;

    const buttons = document.querySelectorAll(".custom-button");
    buttons.forEach((btn) => {
      btn.classList.remove("active");
    });

    button.classList.add("active");

    const selectedFilter = event.currentTarget.textContent;
    setCurrentFilter(selectedFilter);
  }

  const handleLikeToggle = (InfluencerID) => {
    dispatch(updateInfluencerLikes({ influencer_id: InfluencerID }));
  };

  function handleFavoriteClick(event) {
    const button = event.currentTarget;
    button.classList.toggle("active");
    setIsFetchingFavorites((prev) => !prev);
    // if (isFetchingFavorites) {
    //   dispatch(getAllInfluencers());
    // } else {
    //   dispatch(getFavoriteInfluencers());
    // }
  }

  function handleCheckboxChange(option) {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedOptions = {
        ...prevSelectedOptions,
        [currentFilter]: {
          ...(prevSelectedOptions[currentFilter] || {}),
          [option]: !prevSelectedOptions[currentFilter]?.[option],
        },
      };
      return updatedOptions; // Return the updated state
    });

    // Update filterOptionsData state for storing checked options
    setFilterOptionsData((prevFilterOptionsData) => {
      const currentOptions = prevFilterOptionsData[currentFilter] || [];

      if (currentFilter === "Genre") {
        const optionData = filteroptions[currentFilter].find(
          (genre) => genre.value === option
        );

        const updatedOptions = {
          ...prevFilterOptionsData,
          [currentFilter]: selectedOptions[currentFilter]?.[option]
            ? currentOptions.filter((item) => !optionData.value.includes(item))
            : [...currentOptions, ...optionData.value],
        };

        console.log("FilteredOptionsgenre", updatedOptions);
        return updatedOptions;
      } else {
        // Handle other filters
        const updatedOptions = {
          ...prevFilterOptionsData,
          [currentFilter]: selectedOptions[currentFilter]?.[option]
            ? currentOptions.filter((item) => item !== option)
            : [...currentOptions, option],
        };
        console.log("FilteredOptions", updatedOptions);
        return updatedOptions; // Return the updated state
      }

      // const updatedOptions = {
      //   ...prevFilterOptionsData,
      //   [currentFilter]: selectedOptions[currentFilter]?.[option]
      //     ? currentOptions.filter((item) => item !== option)
      //     : [...currentOptions, option],
    });
  }

  const handleClick = (event) => {
    setFilterPopUp(event.currentTarget);
  };

  const open = Boolean(filterPopUp);
  const id = open ? "simple-popover" : undefined;

  const filterClose = () => {
    setFilterPopUp(null);
  };

  const followersPopUpopen = (event) => {
    setFollowersPopUp(event.currentTarget);
  };

  const followersPopUpclose = () => {
    setFollowersPopUp(null);
  };

  const openfollowersPopUp = Boolean(followersPopUp);

  const toggleView = () => {
    setView((prevState) => !prevState);
  };

  const { influencers, loading } = useSelector((state) => state?.influencers);
  function getGenderRatio(number, total) {
    return Math.trunc((number / total) * 100);
  }

  useEffect(() => {
    const queryParams = { ...sortParams, ...filterOptionsData };
    dispatch(getAllInfluencers(queryParams));
  }, [dispatch, sortParams]);

  const handleSortClick = (field) => {
    // Determine the current sort order for the clicked field
    const currentAsc = sortParams[`is${field}SortedAsc`];
    const currentDesc = sortParams[`is${field}SortedDesc`];

    // Update the sortParams state based on the current sort order
    if (!currentAsc && !currentDesc) {
      // If neither asc nor desc is set, set asc
      setSortParams({
        [`is${field}SortedAsc`]: true,
        [`is${field}SortedDesc`]: false,
        // ... update other queryParams accordingly
      });
    } else if (currentAsc) {
      // If currently ascending, toggle to descending
      setSortParams({
        [`is${field}SortedAsc`]: false,
        [`is${field}SortedDesc`]: true,
        // ... update other queryParams accordingly
      });
    } else {
      // If currently descending, reset to default
      setSortParams({
        [`is${field}SortedAsc`]: false,
        [`is${field}SortedDesc`]: false,
        // ... update other queryParams accordingly
      });
    }
  };

  // console.log("filterOptionsData", filterOptionsData);

  const handleApplyFilters = () => {
    dispatch(getAllInfluencers(filterOptionsData));
    filterClose();
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;

    if (searchTerm.trim() === "") {
      dispatch(getAllInfluencers());
    } else {
      // If there is a search term, dispatch an action to search influencers
      dispatch(getSearchedInfluencers(searchTerm));
    }
  };

  function handleProfileClick() {
    navigate("/influencerstats");
  }
  return (
    <>
      <div className="container">
        <div className="InfluencerDashboard">
          <div className="filters">
            <div className="heading">
              {user.role === "admin" ? (
                <div className="tabs-container">
                  {["Brands", "Influencers"].map((tab) => (
                    <button
                      key={tab}
                      className={`tab ${activeTab === tab ? "active" : ""}`}
                      onClick={() => setActiveTab(tab)}
                    >
                      {tab}
                    </button>
                  ))}
                  <div
                    className="underline"
                    style={{
                      width: activeTab === "Brands" ? "80px" : "115px", // Adjust width based on tab text length
                      transform:
                        activeTab === "Brands"
                          ? "translateX(0)"
                          : "translateX(100px)", // Adjust translation for tab position
                    }}
                  />
                </div>
              ) : (
                <span>Influencers</span>
              )}
            </div>
            <div className="all-filters">
              <div className="search">
                <img
                  src={SearchIcon}
                  alt="Search Icon"
                  className="search-icon"
                />
                <input
                  className="search-bar"
                  type="text"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
              <div className="filters-buttons">
                <div
                  className="filters-button favorites"
                  onClick={handleFavoriteClick}
                >
                  <p className="filter-text">Favorites</p>
                  <img src={Heart} alt="Heart Icon" className="heart-icon" />
                </div>
                <div className="filters-button" onClick={handleClick}>
                  <p className="filter-text1">Filters</p>
                  <img src={Vector} alt="Filter Icon" className="filter-icon" />
                </div>
                <div className="view" onClick={toggleView}>
                  {view ? (
                    <img className="viewIcon" src={Grid} alt="View Icon" />
                  ) : (
                    <img className="viewIcon" src={ListIcon} alt="Grid Icon" />
                  )}
                </div>
              </div>
            </div>

            <Popover
              id={id}
              anchorEl={filterPopUp}
              open={open}
              onClose={filterClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div className="filter-container">
                <div className="left-container">
                  <div className="filter-title">
                    <span>Filters</span>
                  </div>
                  <div className="filter-buttons">
                    {Object.keys(filteroptions).map((button, index) => (
                      <button
                        key={index}
                        onClick={handleButtonClick}
                        className={`custom-button ${
                          button === currentFilter ? "active" : ""
                        }`}
                      >
                        {button.label || button}
                      </button>
                    ))}
                  </div>
                </div>
                <div className="right-container">
                  <div className="search">
                    <img
                      src={SearchIcon}
                      alt="Search Icon"
                      className="search-icon"
                    />
                    <input
                      className="search-bar"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <div className="options-container">
                    {filteroptions[currentFilter].map((filter, index) => (
                      <div key={index} className="filter-option">
                        {/* <div > */}
                        {/* <label className="checkbox">
                            <input
                              type="checkbox"
                              checked={
                                selectedOptions[currentFilter]?.[filter.value] ||
                                false
                              }
                              onChange={() => handleCheckboxChange(filter.value)}
                            />
                            <div className="check"></div>
                          </label>
                          {filter.label} */}

                        <label className="checkbox">
                          <input
                            type="checkbox"
                            className="checkbox"
                            checked={
                              selectedOptions[currentFilter]?.[filter.value] ||
                              false
                            }
                            onChange={() => handleCheckboxChange(filter.value)}
                          />
                          <div className="checkmar"></div>
                          {filter.label}
                        </label>
                        {/* </div> */}
                      </div>
                    ))}
                  </div>
                  <div className="results">
                    <span>Showing results for 82 influencers</span>
                  </div>
                  <div className="bottom-container">
                    <span onClick={handleClearAll} className="clear">
                      Clear all
                    </span>
                    <button
                      className="apply-button"
                      onClick={handleApplyFilters}
                    >
                      Apply Filters
                    </button>
                  </div>
                </div>
              </div>
            </Popover>
          </div>
          {loading ? (
            <MuiLoader />
          ) : (
            <div className="influencers-list">
              {view ? (
                // TABLEEEEEEE
                <div className="table-container">
                  <table className="custom-table">
                    <thead>
                      <tr className="">
                        <th style={{ width: `${columnWidths[0]}%` }}>
                          Influencers
                        </th>
                        <th style={{ width: `${columnWidths[1]}%` }}>Genre </th>
                        <th style={{ width: `${columnWidths[2]}%` }}>CPP </th>

                        <th
                          style={{ width: `${columnWidths[3]}%` }}
                          onClick={() => handleSortClick("Engagement")}
                        >
                          Engagement %{" "}
                          <img
                            src={Sort}
                            alt="Sort Icon"
                            className="sort-icon"
                          />
                        </th>
                        <th
                          style={{ width: `${columnWidths[4]}%` }}
                          onClick={() => handleSortClick("Followers")}
                        >
                          Followers{" "}
                          <img
                            src={Sort}
                            alt="Sort Icon"
                            className="sort-icon"
                          />
                        </th>
                        <th style={{ width: `${columnWidths[5]}%` }}>Reach </th>

                        <th
                          style={{ width: `${columnWidths[6]}%` }}
                          className="audience-gender"
                        >
                          Audience Gender{" "}
                        </th>
                        <th style={{ width: `${columnWidths[7]}%` }}></th>
                      </tr>
                    </thead>
                    <tbody>
                      {influencers?.map((influencer, index) =>
                        (isFetchingFavorites && influencer.isliked) ||
                        !isFetchingFavorites ? (
                          <tr key={index} onClick={handleProfileClick}>
                            <td className="profile-info">
                              <div
                                className="profile-image"
                                style={{
                                  backgroundImage: `url(${influencer?.influencer_profile_url})`,
                                }}
                              />
                              <span className="influencer-name">
                                {influencer?.influencer_name}
                              </span>
                            </td>
                            <td className="genre">{influencer?.genre}</td>
                            <td className="cpp">{influencer?.cpp}</td>
                            <td className="engagement">{`${influencer?.engagement} %`}</td>

                            <td
                              className="followers"
                              onMouseEnter={followersPopUpopen}
                              onMouseLeave={followersPopUpclose}
                            >
                              <div className="followers-image">
                                <img src={People} alt="People Icon" />
                                <span>
                                  {formatNumber(influencer?.followers)}
                                </span>
                              </div>
                            </td>
                            <td className="reach">{influencer?.reach}</td>

                            <Popover
                              id={id}
                              sx={{
                                pointerEvents: "none",
                                boxShadow: 1,
                              }}
                              elevation={0}
                              open={openfollowersPopUp}
                              anchorEl={followersPopUp}
                              anchorOrigin={{
                                vertical: "center",
                                horizontal: "right",
                              }}
                              transformOrigin={{
                                vertical: "center",
                                horizontal: "left",
                              }}
                              onClose={followersPopUpclose}
                              disableRestoreFocus
                              PaperProps={{
                                style: {
                                  width: "96px",
                                  height: "103px",
                                  backgroundColor: "#F8E7ED",
                                  color: "rgba(0, 0, 0, 0.8)",
                                  borderRadius: "10px",
                                },
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: "10px",
                              }}
                            >
                              <div className="followers-popover">
                                <div className="instagram">
                                  <img src={Instagram} alt="Instagram Icon" />
                                  <span> 60k </span>
                                </div>
                                <div className="youtube">
                                  <img src={YouTube} alt="YouTube Icon" />
                                  <span> 60k </span>
                                </div>
                                <div className="facebook">
                                  <img src={Facebook} alt="Facebook Icon" />
                                  <span> 60k </span>
                                </div>
                              </div>
                            </Popover>
                            <td>
                              <div className="audience-info">
                                <span className="gender-percentages">
                                  Male{" "}
                                  <span className="male">
                                    {getGenderRatio(
                                      influencer?.male,
                                      influencer?.followers
                                    )}
                                    %
                                  </span>
                                </span>
                                <span className="gender-percentages">
                                  Female{" "}
                                  <span className="female">
                                    {getGenderRatio(
                                      influencer?.female,
                                      influencer?.followers
                                    )}
                                    %
                                  </span>
                                </span>
                                <span className="gender-percentages">
                                  Other{" "}
                                  <span className="other">
                                    {getGenderRatio(
                                      influencer?.other,
                                      influencer?.followers
                                    )}
                                    %
                                  </span>
                                </span>
                              </div>
                            </td>
                            <td
                              className="like-icon-column"
                              onClick={() =>
                                handleLikeToggle(influencer?.influencer_id)
                              }
                              width="1%"
                            >
                              <img
                                src={influencer?.isliked ? Liked : Unliked}
                                alt="Like Icon"
                                className="like-icon"
                              />
                            </td>
                          </tr>
                        ) : (
                          <></>
                        )
                      )}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div className="profile-card">
                  <div className="profile-card-info">
                    <img className="likeIcon" src={Liked} alt="Like Icon" />
                    <img
                      src={Profile_Photo_Rec}
                      className="profile-photo-rec"
                      alt="ProfilePhoto"
                    />
                    <div className="genuin-followers">
                      <div className="circle">
                        <p className="circle-text">80</p>
                      </div>
                      <span className="genuin-text">Genuin</span>
                    </div>
                  </div>
                  <div className="social-info">
                    <div className="account-info">
                      <span className="influencer-name">Bella Ciao</span>
                      <img
                        className="verifyIcon"
                        src={Verify}
                        alt="Verify Icon"
                      />
                      <span className="photography">Photography</span>
                    </div>
                    <div className="social-presence">
                      <div className="heading">
                        <span>Social Presence</span>
                        <img
                          className="importantIcon"
                          src={Important}
                          alt="Important Icon"
                        />
                      </div>
                      <div className="enagagement-ratio">
                        <div className="reach">
                          <span>Reach</span>
                          <br />
                          <span className="followers-count">07 K</span>
                        </div>
                        <div className="reach">
                          <span>Enage</span>
                          <br />
                          <span className="followers-count">07 K</span>
                        </div>
                        <div className="reach">
                          <span>CPP</span>
                          <br />
                          <span className="followers-count">$ 5K</span>
                        </div>
                      </div>
                      <div className="line"></div>
                    </div>
                    <div className="social-media">
                      <div className="heading">
                        <span>Followers</span>
                      </div>
                      <div className="social-media-links">
                        <div>
                          <img src={Instagram} alt="People Icon" />
                          <span>120 K</span>
                        </div>
                        <div>
                          <img src={YouTube} alt="People Icon" />
                          <span>80 K</span>
                        </div>
                        <div>
                          <img src={Facebook} alt="People Icon" />
                          <span>80 K</span>
                        </div>
                      </div>
                    </div>
                    <div className="line"></div>
                    <div className="audience">
                      <div className="heading">
                        <span>Audience</span>
                      </div>
                      <div className="genders">
                        <div className="male">
                          <span>Male</span>
                          <span className="audience-percantage">63 %</span>
                        </div>
                        <div className="female">
                          <span>Female</span>
                          <span className="audience-percantage">63 %</span>
                        </div>
                        <div className="other">
                          <span>Others</span>
                          <span className="audience-percantage">7 %</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default InfluencerDashboard;
