// App.jsx (continued)
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import axios from "axios";

const callPowerAutomate = async () => {
  try {
    const response = await axios.post(
      `https://prod2-05.centralindia.logic.azure.com:443/workflows/1a569d76079b4efd82a73734b1b2347e/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=iYAFH4r5v7UCOTjCToxmCDVqFaHH8X-8Vj-K4I9r68w`
    );
    return response;
  } catch (err) {
    return err;
  }
};

async function fetchPowerAutomate() {
  const response = await callPowerAutomate();
  console.log("automate response", response);
}

const Template1 = () => {
  return (
    <div>
      This is Template 1
      <button onClick={() => fetchPowerAutomate()}>Call Power Automate</button>
    </div>
  );
};

const Template2 = () => {
  return (
    <div>
      This is Template 2
      <button onClick={() => fetchPowerAutomate()}>Call Power Automate</button>
    </div>
  );
};

const App = () => {
  const getHostBasedComponent = () => {
    const hostName = window.location.hostname;
    if (hostName === "cepheiinfotech.io" || hostName == "localhost") {
      return <Template1 />;
    } else if (hostName === "cepheiinfotech.in") {
      return <Template2 />;
    } else {
      return <Navigate to="/default" />;
    }
  };

  const getAboutComponent = () => {
    const hostName = window.location.hostname;
    console.log("hostName", hostName);
    if (hostName === "cepheiinfotech.io" || hostName == "localhost") {
      return <div>About Template 1</div>;
    } else if (hostName === "cepheiinfotech.in") {
      return <div>About Template 2</div>;
    } else {
      return <Navigate to="/default" />;
    }
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={getHostBasedComponent()} />
        <Route path="/default" element={<div>Default Page</div>} />
        <Route path="/about" element={getAboutComponent()} />
      </Routes>
    </Router>
  );
};

export default App;
