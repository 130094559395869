// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-dashboard {
  display: flex;
  overflow: hidden;
}
.main-dashboard .content {
  margin-top: 4.425rem;
}

body {
  margin: 0;
}

@media screen and (max-width: 768px) {
  .main-dashboard .content {
    margin-left: 0%;
    margin-top: 4.425rem;
  }
}
@media (max-width: 768px) {
  /* For medium screens */
  body {
    font-size: 14px !important;
  }
  span,
  h3 {
    font-size: 14px !important;
  }
}
@media (max-width: 576px) {
  /* For small screens */
  body {
    font-size: 12px !important;
  }
  span,
  h3 {
    font-size: 14px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;AACF;AACE;EACE,oBAAA;AACJ;;AAIA;EACE,SAAA;AADF;;AAIA;EAEI;IACE,eAAA;IAEA,oBAAA;EAHJ;AACF;AAQA;EAEE,uBAAA;EACA;IACE,0BAAA;EAPF;EAUA;;IAEE,0BAAA;EARF;AACF;AAYA;EAEE,sBAAA;EACA;IACE,0BAAA;EAXF;EAcA;;IAEE,0BAAA;EAZF;AACF","sourcesContent":[".main-dashboard {\n  display: flex;\n  overflow: hidden;\n\n  .content {\n    margin-top: 4.425rem;\n  }\n\n}\n\nbody {\n  margin: 0;\n}\n\n@media screen and (max-width: 768px) {\n  .main-dashboard {\n    .content {\n      margin-left: 0%;\n\n      margin-top: 4.425rem;\n    }\n  }\n}\n\n\n@media (max-width: 768px) {\n\n  /* For medium screens */\n  body {\n    font-size: 14px !important;\n  }\n\n  span,\n  h3 {\n    font-size: 14px !important;\n\n  }\n}\n\n@media (max-width: 576px) {\n\n  /* For small screens */\n  body {\n    font-size: 12px !important;\n  }\n\n  span,\n  h3 {\n    font-size: 14px !important;\n\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
