import React from "react";
import "./InfluencerStats.scss";
import Sampleimg from "../assets/sampleimg.png";
import Mailbox from "../assets/mailbox.svg";
import LocationSVG from "../assets/LocationSVG.svg";
import YoutubeButton from "../assets/youtubeButton.svg";
import InstagramIcon from "../assets/instagramICON.svg";
import BackVector from "../assets/BackVector.svg";
import InstagramAccordion from "./InstaAccordion";
import YoutubeAccordion from "./YoutubeAccordion";
import { useNavigate } from "react-router-dom";
import { Grid, Typography, Box } from "@mui/material";

const InfluencerStats = () => {
  const navigate = useNavigate();
  return (
    <div className="influencer-stats">
      <button onClick={() => navigate("/home")}>
        <img src={BackVector} alt="back" />
        Back
      </button>
      <Box className="stat-item">
        <Grid container spacing={2}>
          {/* Contact Section */}
          <Grid item xs={12} sm={4} md={4}>
            <Box className="contact">
              <Typography variant="h6">Contact</Typography>
              <Box className="contact-details">
                <Box className="sub-div" display="flex" alignItems="center">
                  <img src={Mailbox} alt="mail" />
                  <small> johnmorgan@gmail.com</small>
                </Box>
                <Box className="sub-div" display="flex" alignItems="center">
                  <img src={LocationSVG} alt="location" />
                  <small> Andheri west, Mumbai</small>
                </Box>
              </Box>
            </Box>
          </Grid>

          {/* Profile Section */}
          <Grid item xs={12} sm={4} md={4}>
            <Box className="profile" textAlign="center">
              <Typography variant="h5">Cardi B</Typography>
              <Box className="image-box" mt={2}>
                <img
                  src={Sampleimg}
                  alt="img"
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </Box>
              <Typography variant="body2">Fashion</Typography>
            </Box>
          </Grid>

          {/* Socials Section */}
          <Grid item xs={12} sm={4} md={4}>
            <Box className="contact">
              <Typography variant="h6">Socials</Typography>
              <Box className="contact-details">
                <Box className="sub-div" display="flex" alignItems="center">
                  <img src={InstagramIcon} alt="instagram" />
                  <small>john_morgan</small>
                </Box>
                <Box className="sub-div" display="flex" alignItems="center">
                  <img src={YoutubeButton} alt="youtube" className="youtube" />
                  <small> www.youtube.com/@realtalkclipp</small>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <InstagramAccordion />

      <YoutubeAccordion />
    </div>
  );
};

export default InfluencerStats;
