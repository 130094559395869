import React, { useState, useEffect } from "react";
import SearchIcon from "../assets/Search.svg";
import "./Conversations.scss";
import DefaultUserProfile from "../assets/DefaultUserProfile.jpeg";
import axios from "axios";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";

function Conversations({ onSelectConversation, socket }) {
  const { user } = useSelector((state) => state.user || {});
  const [conversations, setConversations] = useState([]);
  const [suggestedUsers, setSuggestedUsers] = useState([]);
  const [conversationCreated, setConversationCreated] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [updateConversation, setUpdateConversation] = useState(false);
  const [selectedConversationId, setSelectedConversationId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // State to control sidebar visibility

  

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat/conversation/${user?.user_id}`
      )
      .then((response) => {
        setConversations(response.data);
      })
      .catch((error) => {
        console.error("Error fetching conversations:", error);
      });
  }, [user, conversationCreated, updateConversation]);

  useEffect(() => {
    socket?.on("updateConversations", (updatedConversations) => {
      setConversations((prevConversations) => {
        return prevConversations.map((conversation) => {
          const updatedConversation = updatedConversations.find(
            (updatedConv) =>
              updatedConv.conversationId === conversation.conversationId &&
              updatedConv.user.receiverId !== user.user_id
          );
          if (updatedConversation) {
            return updatedConversation;
          } else {
            return conversation;
          }
        });
      });
    });

    return () => {
      socket?.off("updateConversations");
    };
  }, [socket]);

  useEffect(() => {
    if (conversations.length === 0) {
      axios
        .get(
          `${process.env.REACT_APP_BACKEND_URL}/api/chat/suggestedUsers/${user?.user_id}`
        )
        .then((response) => {
          setSuggestedUsers(response.data);
        })
        .catch((error) => {
          console.error("Error fetching suggested users:", error);
        });
    }
  }, [conversations.length, user]);

  const handleSuggestedUserClick = (conversation) => {
    setSearchText("");
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/api/chat/conversation`, {
        senderId: user.user_id,
        receiverId: conversation.id,
      })
      .then((response) => {
        onSelectConversation(response.data[0]);
        setConversationCreated(true);
        setUpdateConversation((prevState) => !prevState);
        setSearchResults([]);
      })
      .catch((error) => {
        console.error("Error creating conversation:", error);
      });
  };

  const handleConversationClick = (conversation) => {
    setSelectedConversationId(conversation.conversationId);
    onSelectConversation(conversation);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return "";
    const date = new Date(timestamp);
    const month = date.toLocaleString("en-us", { month: "short" });
    const day = date.getDate();
    return `${month} ${day}`;
  };

  useEffect(() => {
    if (searchText.trim() !== "") {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/chat/searchUser`, {
          params: {
            searchText: searchText,
            userId: user?.user_id,
          },
        })
        .then((response) => {
          setSearchResults(response.data);
        })
        .catch((error) => {
          console.error("Error fetching users based on search text:", error);
        });
    }
  }, [searchText, user, conversationCreated]);

  function truncateMessage(message) {
    const maxLength = 30;
    if (message && message.length > maxLength) {
      return message.substring(0, maxLength) + "...";
    } else {
      return message;
    }
  }
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev); // Toggle the sidebar visibility
  };

  return (
    
    <div className="conversations">
      <div className="search-users">
        <img src={SearchIcon} alt="Search Icon" className="search-icon" />
        <input
          className="search-user"
          type="text"
          placeholder={
            user.role === "influencer"
              ? "Search for Brands"
              : "Search for Influencers"
          }
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>
      {conversations.length === 0 && searchResults.length === 0 && (
        <>
          <div className="suggestedUsers">
            {user.role === "influencer" ? (
              <span>Brands you have Interacted Recently</span>
            ) : user.role === "brand" ? (
              <span>Influencer Interacted with campaign</span>
            ) : null}
          </div>
          {suggestedUsers.map((user, index) => (
            <div
              key={index}
              className="conversation-data"
              onClick={() => handleSuggestedUserClick(user.user)}
              style={{ marginTop: index === 0 ? "0rem" : "0" }}
            >
              <img
                className="user-profile"
                src={DefaultUserProfile}
                alt="User Profile"
              />
              <div className="user-name">
                <span className="name">{user.user.fullName}</span>
              </div>
            </div>
          ))}
        </>
      )}
      {conversations.map((conversation, index) => {
        const sender = conversation.last_message_sender_id;
        const lastMessage =
          sender === user.user_id
            ? `You: ${conversation.last_msg}`
            : conversation.last_msg;

        return (
          <div
            key={conversation.conversationId}
            className={`conversation-data ${
              selectedConversationId === conversation.conversationId
                ? "selected"
                : ""
            }`}
            onClick={() => handleConversationClick(conversation)}
            style={{ marginTop: index === 0 ? "2.5rem" : "0" }}
          >
            <img
              className="user-profile"
              src={conversation.user.user_profile || DefaultUserProfile}
              alt="User Profile"
            />
            <div className="user-info">
              <div className="user-name">
                <span className="name">{conversation.user.fullName}</span>
                <span className="time">
                  {formatDate(conversation.last_message_sent_at)}
                </span>
              </div>
              <div
                className={`last-msg ${
                  sender ? "sent-by-user" : "sent-by-other"
                }`}
              >
                {truncateMessage(lastMessage)}
              </div>
            </div>
          </div>
        );
      })}

      {searchResults.map((user, index) => (
        <div
          key={index}
          className="conversation-data"
          onClick={() => handleSuggestedUserClick(user)}
          style={{ marginTop: index === 0 ? "1rem" : "0" }}
        >
          <img
            className="user-profile"
            src={DefaultUserProfile}
            alt="User Profile"
          />
          <div className="user-name">
            <span className="name">{user.fullName}</span>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Conversations;
