import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";
import "./Profile.scss";
import Mailbox from "../assets/mailbox.svg";
import LocationSVG from "../assets/LocationSVG.svg";
import YoutubeButton from "../assets/youtubeButton.svg";
import InstagramIcon from "../assets/instagramICON.svg";
import BackVector from "../assets/BackVector.svg";
import InstagramAccordion from "../InfluencerStatsPage/InstaAccordion";
import YoutubeAccordion from "../InfluencerStatsPage/YoutubeAccordion";
import HomeImg from "../assets/payment-home-img.png";
import { useSelector } from "react-redux";
import ContractPopup from "../Contract/ContractPopup";
import UpiHandlePopUp from "../UpiHandlePopUp/UpiHandlePopUp";
import BankDetialsPopUp from "../BankDetialsPopUp/BankDetialsPopUp";

const InfluencerStats = () => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [ViewPaymentPopup, setViewPaymentPopup] = useState(false);

  const { user: currentUser } = useSelector((state) => state?.user || {});

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleButtonClick = () => {
    navigate("/paymentdetails");
  };

  const handleViewPayment = () => {
    setViewPaymentPopup(true);
  };

  const handleCloseViewPayment = () => {
    setViewPaymentPopup(false);
  };

  const handleAddPaymentMethod = () => {
    console.log("Adding payment method...");
    handleOpenPopup();
  };

  const handlePrev = () => {
    navigate("/home");
  };

  return (
    <div className="payment-influencer-stats">
      <button onClick={handlePrev}>
        <img src={BackVector} alt="back" />
        Back
      </button>
      <div className="stat-item">
        <div className="contact">
          <p>Contact</p>
          <div className="contact-details">
            <div className="sub-div">
              <img src={Mailbox} alt="mail" />
              <small>{currentUser?.email}</small>
            </div>
            <div className="sub-div">
              <img src={LocationSVG} alt="location" />
              <small> {`${currentUser.area} , ${currentUser.state} `}</small>
            </div>
          </div>
        </div>
        <div className="profile">
          <h3>{currentUser?.first_name}</h3>
          <div className="image-box">
            <img src={currentUser?.user_photo} alt="img" />
          </div>
          <p>{currentUser?.categories[0]}</p>
        </div>
        <div className="contact">
          <p>Socials</p>
          <div className="contact-details">
            {currentUser?.instagram_link && (
              <div className="sub-div">
                <img src={InstagramIcon} alt="instagram" />
                <small>{currentUser?.instagram_link}</small>
              </div>
            )}
            {currentUser?.youtube_link && (
              <div className="sub-div">
                <img src={YoutubeButton} alt="youtube" className="youtube" />
                <small> {currentUser?.youtube_link}</small>
              </div>
            )}
          </div>
        </div>
      </div>

      <InstagramAccordion />

      <YoutubeAccordion />

      <div className="payment-container">
        {currentUser?.has_bank_details_filled ? (
          <p onClick={() => handleViewPayment()}>view Payment Method</p>
        ) : (
          <p onClick={handleAddPaymentMethod}>Add Payment Method</p>
        )}
      </div>

      {currentUser?.upi_id ? (
        <UpiHandlePopUp
          onClose={handleCloseViewPayment}
          open={ViewPaymentPopup}
        />
      ) : (
        <BankDetialsPopUp
          onClose={handleCloseViewPayment}
          open={ViewPaymentPopup}
        />
      )}

      <Dialog
        open={openPopup}
        onClose={handleClosePopup}
        fullWidth
        maxWidth="md"
        sx={{ "& .MuiDialog-paper": { borderRadius: 3, width: "60%" } }}
      >
        <DialogTitle
          style={{
            fontFamily: "poppins",
            textAlign: "center",
            color: "var(--grey-500, #50484A)",
            margin: "0.5rem",
          }}
        >
          Unlock faster payouts!
        </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <img src={HomeImg} alt="" style={{ width: "15rem" }} />

          <p
            style={{
              fontFamily: "poppins",
              textAlign: "center",
              color: "var(--grey-500, #4c3339)",
              width: "28rem",
              fontSize: "17px",
              margin: "0.5rem 1rem",
            }}
          >
            Adding your payment method lets you receive collaboration earnings
            from brands within hours. Don't miss out on timely payments!
          </p>
          <button
            className="add-payment-button"
            onClick={handleButtonClick}
            style={{
              width: "fit-content",
              padding: "1rem",
              fontFamily: "poppins",
              fontSize: "14px",
              borderRadius: "5px",
              backgroundColor: "#E52D5E",
              margin: "1rem 1rem",
            }}
          >
            Add Payment Method
          </button>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default InfluencerStats;
