import React, { Fragment } from "react";
import DefaultProfileIcon from "@mui/icons-material/AccountCircle"; 
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import WarningIcon from "@mui/icons-material/Warning";
import { Step, StepLabel, Stepper, Typography } from "@mui/material";

const CheckoutSteps = ({activeStep}) => {
  const steps = [
    {
      label: <Typography>Campaign Details</Typography>,
      icon: <DefaultProfileIcon />,
    },
    {
      label: <Typography>Audience</Typography>,
      icon: <EditIcon />,
    },
    {
      label: <Typography>Deliverables</Typography>,
      icon: <AddIcon />,
    },
    {
      label: <Typography>Overview</Typography>,
      icon: <WarningIcon />,
    },
  ];

  const stepStyles = {
    boxSizing: "border-box",
  };

  return (
    <Fragment>
      <Stepper alternativeLabel activeStep={activeStep} style={stepStyles}>
        {steps.map((item, index) => (
          <Step
            key={index}
            active={activeStep === index ? true : false}
            completed={activeStep > index ? true : false}
          >
            <StepLabel
              style={{
                color: activeStep >= index ? "tomato" : "rgba(0, 0, 0, 0.649)",
              }}
              icon={item.icon}
            >
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Fragment>
  );
};

export default CheckoutSteps;
